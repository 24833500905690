import React, { useEffect, useState } from "react"
import styled from "styled-components"
import { StarPageHeader } from "../../components/PageHeader"
import GiftAnimation from "../../components/GiftAnimation"
import { PageComponent } from "../../components/PageComponent"
import LoadingComponent from "../../components/LoadingComponent"
import GiftDetails, {
  StarGiftDetailsVersion2,
  StarExpiryVersion2,
  StarTermsAndConditionsVersion2,
} from "../../components/GiftDetails"
import RedemptionInstructions, {
  StarRedemptionInstructionsVersion2,
} from "../../components/RedemptionInstructions"
import { StarBalanceCheckInstructionsVersion2 } from "../../components/BalanceCheckInstructions"
import AddToWalletVersion2 from "../../components/WalletComponent"
import ViewGift, { StarButtonBlockVersion2 } from "../../components/ViewGift"
import { BarcodeDetails } from "../../components/GiftCardCertificate"
import StarNavBar from "../../components/StarNavbar"

function checkURLHash(
  setPageNotFound,
  setGiftDetails,
  setGiftCard,
  setGiftWrapper,
  setGreetingCard,
  setGreetingMessage,
  urlHash
) {
  const url = `${process.env.GATSBY_STAR_BACKEND_API}/api/v1/received-gift/${urlHash}/`
  fetch(url, {
    method: "GET",
    mode: "cors",
  })
    .then(response => {
      if (response.status === 200) {
        return response.json()
      } else {
        throw Error("Invalid URL")
      }
    })
    .then(result => {
      fetch(
        `${process.env.GATSBY_STAR_BACKEND_API}/api/v1/giftcard-orders/${result.gift_id}/`,
        {
          method: "GET",
          mode: "cors",
        }
      )
        .then(response => {
          if (response.status === 200) {
            return response.json()
          }
          throw Error("Invalid URL")
        })
        .then(result => {
          const giftDetails = result
          setGiftDetails(giftDetails)
          if (giftDetails) {
            setGiftCard({
              brand_name: giftDetails.brand_name,
              product_image: `${process.env.GATSBY_STAR_BACKEND}${giftDetails.product_image}`,
              redemption_instructions: giftDetails.redemption_instructions,
            })
            if (giftDetails.experience.status) {
              setGreetingCard(
                giftDetails.experience.greeting_card
                  ? {
                      product_image: `${process.env.GATSBY_STAR_BACKEND}${giftDetails.experience.greeting_card.front_image}`,
                      product_inner_image: `${process.env.GATSBY_STAR_BACKEND}${giftDetails.experience.greeting_card.inner_image}`,
                    }
                  : null
              )
              setGiftWrapper(
                giftDetails.experience.gift_wrapper
                  ? {
                      product_image: `${process.env.GATSBY_STAR_BACKEND}${giftDetails.experience.gift_wrapper.product_image}`,
                    }
                  : null
              )
            }
            setGreetingMessage(giftDetails.greeting_message)
          } else {
            setPageNotFound(true)
          }
        })
        .catch(error => {
          console.log(error)
          setPageNotFound(true)
        })
    })
    .catch(error => {
      console.log(error)
      setPageNotFound(true)
    })
}

export function ReceivedGiftVersion1({ giftDetails, giftcard }) {
  return (
    <>
      <GiftDetails giftDetails={giftDetails} giftcard={giftcard} />
      <RedemptionInstructions giftcard={giftDetails} />
      <ViewGift giftDetails={giftDetails} />
    </>
  )
}

const ReceivedGiftVersion2Wrapper = styled.div`
  display: grid;
  justify-items: center;
  width: 100%;
  border-radius: 15px;
  box-shadow: 0 0 10px 0 #2d2d2d20;
  margin-top: 1rem;
  padding: 3rem;
  @media (max-width: 550px) {
    padding: 3rem 1rem;
    box-shadow: none;
  }
`

const HorizontalLine = styled.div`
  width: 100%;
  height: 1px;
  border-top: 1px solid #d2d5d975;
  margin: 1rem 0;
`

function ReceivedGiftVersion2({ giftDetails, giftcard }) {
  return (
    <ReceivedGiftVersion2Wrapper className="received-gift-v2__wrapper">
      <StarGiftDetailsVersion2 giftDetails={giftDetails} giftcard={giftcard} />
      {giftDetails && giftDetails.show_barcode && (
        <BarcodeDetails giftDetails={giftDetails} />
      )}
      <StarExpiryVersion2 giftDetails={giftDetails} />
      <StarTermsAndConditionsVersion2 giftDetails={giftDetails} />
      <StarButtonBlockVersion2 giftDetails={giftDetails} />
      <HorizontalLine />
      <StarRedemptionInstructionsVersion2 giftcard={giftDetails} />
      {giftDetails && giftDetails.balance_check_instructions && (
        <>
          <HorizontalLine />
          <StarBalanceCheckInstructionsVersion2 giftcard={giftDetails} />
        </>
      )}
      <AddToWalletVersion2 giftcard={giftDetails} />
      <FooterComponentWrapper>
        <span>&nbsp;</span>
        <FooterComponent>
          <a
            href="https://giftcards.star.com.au/the-star-sydney/FAQs/"
            target="_blank"
            rel="noopener noreferrer nofollow"
          >
            FAQs
          </a>
          <a
            href="https://giftcards.star.com.au/the-star-sydney/contact-us/"
            target="_blank"
            rel="noopener noreferrer nofollow"
          >
            Contact Us
          </a>
          <a
            href="https://giftcards.star.com.au/the-star-sydney/balance-check/"
            target="_blank"
            rel="noopener noreferrer nofollow"
          >
            Balance Check
          </a>
        </FooterComponent>
        <span>&nbsp;</span>
      </FooterComponentWrapper>
    </ReceivedGiftVersion2Wrapper>
  )
}

const SpaceDiv = styled.div`
  width: 100%;
  height: 2rem;
`

const FooterComponentWrapper = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 50px 1fr 50px;
`

const FooterComponent = styled.div`
  width: 100%;
  height: 47px;
  border-top: 2px solid #c9ab5d;
  margin-top: 3rem;
  display: grid;
  grid-template-columns: 1fr auto 1fr;
  justify-content: center;
  justify-items: center;
  align-items: center;
  @media (max-width: 550px) {
    grid-template-columns: 1fr;
    grid-gap: 0.5rem;
    padding: 1rem 0;
  }
  &:first-of-type {
    justify-self: right;
  }
  &:last-of-type {
    justify-self: left;
  }
  a {
    font-family: "CamptonMedium";
  }
`

export default function ReceivedGiftPage(props) {
  const [pageNotFound, setPageNotFound] = useState(false)
  const [giftDetails, setGiftDetails] = useState(null)
  const [giftcard, setGiftCard] = useState(null)
  const [giftwrapper, setGiftWrapper] = useState(null)
  const [greetingcard, setGreetingCard] = useState(null)
  const [greetingMessage, setGreetingMessage] = useState(null)

  useEffect(() => {
    if (props.location.search) {
      const urlHash = props.location.search.replace("?", "")

      checkURLHash(
        setPageNotFound,
        setGiftDetails,
        setGiftCard,
        setGiftWrapper,
        setGreetingCard,
        setGreetingMessage,
        urlHash
      )
    } else {
      window.location.replace("https://giftcards.star.com.au/")
    }
  }, [])

  return (
    <>
      <StarNavBar />
      <SpaceDiv />
      <PageComponent className="received-gift-page">
        {!giftcard ? (
          <>
            {!pageNotFound ? (
              <LoadingComponent />
            ) : (
              window.location.replace("https://giftcards.star.com.au/")
            )}{" "}
          </>
        ) : (
          <>
            <StarPageHeader giftDetails={giftDetails} />
            {false && (
              <GiftAnimation
                giftDetails={giftDetails}
                giftcard={giftcard}
                giftwrapper={giftwrapper}
                greetingcard={greetingcard}
                greetingMessage={greetingMessage}
              />
            )}
            <ReceivedGiftVersion2
              giftDetails={giftDetails}
              giftcard={giftcard}
            />
          </>
        )}
      </PageComponent>
    </>
  )
}
